<template>
  <div v-if="page_loading">
    <Loading :page_loading="page_loading" />
  </div>
  <div v-else>
    <Loading :loading="loading" />

    <!-- นำทาง -->
    <v-card elevation="0" class="v-card-margin-bottom-30">
      <v-card-title class="body-1">
        <a @click="toBack('delivery-note')"> ใบส่งสินค้า </a>
        <v-icon>mdi-chevron-right</v-icon>
        {{ title }}
      </v-card-title>
    </v-card>

    <!-- ข้อมูล -->
    <v-card elevation="1" class="v-card-margin-bottom-30">
      <v-card-title>
        <div class="py-1">
          <v-icon size="22" color="red darken-3">fa-address-card</v-icon
          ><span class="ml-3">ข้อมูล</span>
        </div>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-row>
          <v-col cols="12" md="6">
            <v-row>
              <v-col cols="12" md="2" />
              <v-col cols="12" md="2" class="pt-1 pb-2"> ชื่อคนขับ : </v-col>
              <v-col cols="12" md="8" class="pt-1 pb-2">
                <v-text-field
                  :value="OutboundInfo.driverName"
                  @change="(value) => (OutboundInfo.driverName = Trim_value(value))"
                  outlined
                  dense
                  hide-details
                  autocomplete="off"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="6" />
        </v-row>

        <v-row>
          <v-col cols="12" md="6">
            <v-row>
              <v-col cols="12" md="2" />
              <v-col cols="12" md="2" class="pt-1 pb-2"> เบอร์คนขับ : </v-col>
              <v-col cols="12" md="8" class="pt-1 pb-2">
                <v-text-field
                  :value="OutboundInfo.phone"
                  @change="(value) => (OutboundInfo.phone = Trim_value(value))"
                  outlined
                  maxlength="10"
                  dense
                  hide-details
                  autocomplete="off"
                  @keypress="isNumberWNoDot($event)"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="6" />
        </v-row>

        <v-row>
          <v-col cols="12" md="6">
            <v-row>
              <v-col cols="12" md="2" />
              <v-col cols="12" md="2" class="pt-1 pb-2"> เลขทะเบียน : </v-col>
              <v-col cols="12" md="8" class="pt-1 pb-2">
                <v-text-field
                  :value="OutboundInfo.carPlate"
                  @change="(value) => (OutboundInfo.carPlate = Trim_value(value))"
                  outlined
                  dense
                  hide-details
                  autocomplete="off"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="6" />
        </v-row>

        <v-row>
          <v-col cols="12" md="6">
            <v-row>
              <v-col cols="12" md="2" />
              <v-col cols="12" md="2" class="pt-1 pb-2"> หมายเหตุ : </v-col>
              <v-col cols="12" md="8" class="pt-1 pb-2">
                <v-textarea
                  :value="OutboundInfo.remark"
                  @change="(value) => (OutboundInfo.remark = Trim_value(value))"
                  dense
                  hide-details
                  autocomplete="off"
                  outlined
                  rows="1"
                  row-height="15"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="6">
            <v-row>
              <v-col cols="12" class="pt-1 pb-2">
                <v-btn
                  v-if="btnLoadingMaster"
                  :loading="btnLoadingMaster"
                  :elevation="1"
                  color="primary"
                  class="pa-2"
                >
                  <v-icon size="14" class="mr-1">fa-solid fa-floppy-disk</v-icon>
                  บันทึก
                </v-btn>
                <v-btn
                  v-else
                  :elevation="1"
                  color="primary"
                  class="pa-2"
                  @click="UpdateOutboundInfo()"
                >
                  <v-icon size="14" class="mr-1">fa-solid fa-floppy-disk</v-icon>
                  บันทึก
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-row class="mt-8">
      <v-col cols="3">
        <v-card class="text-center">
          <v-card-text>
            <h1>
              {{ formatMoney(total) }}
            </h1>
            <br />
            <span> ทั้งหมด </span>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="3">
        <v-card class="text-center">
          <v-card-text>
            <h1 style="color: green">
              {{ formatMoney(save) }}
            </h1>
            <br />
            <span> บันทึกแล้ว </span>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="3">
        <v-card class="text-center">
          <v-card-text>
            <h1 style="color: Red">
              {{ formatMoney(notSave) }}
            </h1>
            <br />
            <span> ยังไม่บันทึก </span>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="3">
        <v-card class="text-center">
          <v-card-text>
            <h1 style="color: Orange">
              {{ formatMoney(duplicate) }}
            </h1>
            <br />
            <span>
              <span>ซ้ำ</span>
            </span>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- รายละเอียด -->
    <v-card elevation="1" class="v-card-margin-bottom-30 mt-8">
      <v-card-title>
        <v-row>
          <v-col>
            <v-icon size="22" color="orange darken-3">mdi mdi-view-list</v-icon
            ><span class="ml-3">รายละเอียด</span>
          </v-col>
          <v-col align="right">
            <vue-excel-xlsx
              id="GetExcelStockShopItem"
              v-show="false"
              :data="ExcelData"
              :columns="ExcelHeader"
              :file-name="ExcelFileName"
              :sheet-name="'sheet1'"
            >
            </vue-excel-xlsx>
            <v-btn
              :elevation="1"
              outlined
              color="success"
              class="pa-2 ml-2 mt-1"
              @click="ExportExcel('GetExcelStockShopItem')"
            >
              <v-icon size="14" class="pr-1">fa-solid fa-download</v-icon>
              <span class="fn-12"> Export Excel </span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-row>
          <v-col cols="12" md="6">
            <v-row>
              <v-col cols="12" md="2" />
              <v-col cols="12" md="2" class="pt-1 pb-2"> TrackingNo : </v-col>
              <v-col cols="12" md="8" class="pt-1 pb-2">
                <v-text-field
                  autofocus
                  :value="trackingNo"
                  @change="(value) => (trackingNo = Trim_value(value))"
                  @keyup.enter="AddTrackNoList()"
                  ref="refTrackingNo"
                  outlined
                  dense
                  hide-details
                  autocomplete="off"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="6">
            <v-row>
              <v-col cols="12" class="pt-1 pb-2">
                <v-btn
                  :elevation="1"
                  color="primary"
                  class="pa-2"
                  @click="AddTrackNoList()"
                >
                  <v-icon size="14" class="mr-1">fa-solid fa-plus</v-icon>
                  เพิ่ม
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-data-table
          :headers="header"
          :items="outboundItem"
          item-key="idkey"
          :items-per-page="9999999999"
          hide-default-footer
          :mobile-breakpoint="0"
          class="packhai-border-table mt-3"
        >
          <template v-slot:item.no="{ item }">
            {{ formatMoney(outboundItem.length - outboundItem.indexOf(item)) }}
          </template>
          <template v-slot:item.delete="{ item }">
            <a @click="Delete(item)"
              ><v-icon size="18" color="red darken-3">fa-trash-alt</v-icon></a
            >
          </template>

          <template v-slot:item.created="{ item }">
            <span v-if="item.created != null" class="fn-12">
              {{ formatDatetime(item.created) }}
            </span>
          </template>
          <template v-slot:item.status="{ item }">
            <span v-if="item.status == 2" class="color-green">
              {{ CheckStatus(item.status) }}
            </span>
            <span v-else-if="item.status == 3" class="color-red">
              {{ CheckStatus(item.status) }}
            </span>
            <span v-else-if="item.status == 4" class="color-orange">
              {{ CheckStatus(item.status) }}
            </span>
            <span v-else> {{ CheckStatus(item.status) }} </span>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import { header_token } from "@/website/token";
import Loading from "@/website/components/Loading";
import { branchService_dotnet } from "@/website/global";
import { AlertSuccess, AlertError } from "@/website/global_alert";
import {
  formatDatetime,
  formatDatetime1,
  GenDN,
  GenPA,
  ChangeIE,
  Trim_value,
  formatMoney,
  isNumberWNoDot,
  DateNowT,
  GenIdKey,
} from "@/website/global_function";
export default {
  components: {
    Loading,
  },
  data: (vm) => ({
    // loading
    language: "th",
    page_loading: true,
    loading: false,
    btnLoadingMaster: false,
    btnLoadingItem: false,
    ExcelFileName: null,
    ExcelData: null,
    title: null,
    OutboundID: 0,
    Ie: null,
    trackingNo: null,

    OutboundInfo: {
      id: 0,
      branchID: parseInt(localStorage.getItem("Branch_BranchID")),
      createdBy: parseInt(localStorage.getItem("Branch_StaffID")),
      driverName: null,
      phone: null,
      carPlate: null,
      remark: null,
    },
    outboundItem: [],
    header: [
      { text: "No", align: "center", sortable: false, value: "no", width: "60px" },
      { text: "ลบ", align: "center", sortable: false, value: "delete", width: "60px" },
      {
        text: "TrackingNo",
        align: "left",
        sortable: false,
        value: "trackingNo",
        width: "200px",
      },
      {
        text: "ออเดอร์",
        align: "left",
        sortable: false,
        value: "orderMasterId",
        width: "100px",
      },
      {
        text: "เลขอ้างอิง",
        align: "left",
        sortable: false,
        value: "referenceNumber",
        width: "250px",
      },
      {
        text: "วันเวลา",
        align: "center",
        sortable: false,
        value: "created",
        width: "160px",
      },
      { text: "โดย", align: "center", sortable: false, value: "createdByName" },
      { text: "สถานะ", align: "left", sortable: false, value: "status" },
      { text: "หมายเหตุ", align: "left", sortable: false, value: "remark" },
    ],

    ExcelHeader: [
      { label: "No", field: "no", width: "60px" },
      { label: "TrackingNo", field: "trackingNo", width: "20px" },
      { label: "ออเดอร์", field: "orderMasterId", width: "100px" },
      { label: "เลขอ้างอิง", field: "referenceNumber", width: "250px" },
      { label: "วันเวลา", field: "createdExcel", width: "160px" },
      { label: "โดย", field: "createdByName", width: "160px" },
      { label: "สถานะ", field: "statusExcel", width: "100px" },
      { label: "หมายเหตุ", field: "remark", width: "200px" },
    ],

    total: 0,
    save: 0,
    notSave: 0,
    duplicate: 0,
    idkeyList: [],
    createOutbound: true,
    audio1: null,
    audio2: null,
    status: [
      { id: 0, name: "รอทำรายการ" },
      { id: 1, name: "กำลังบันทึก" },
      { id: 2, name: "บันทึกแล้ว" },
      { id: 3, name: "บันทึกไม่สำเร็จ" },
      { id: 4, name: "ซ้ำ" },
    ],
    tempRowID: 1,
  }),
  watch: {
    outboundItem() {
      this.total = this.outboundItem.length;
    },
  },
  async created() {
    this.OutboundID = parseInt(atob(this.$route.query.id));
    this.SetTitle();
    if (this.OutboundID > 0) await this.LoadData();
    this.page_loading = false;
    this.audio1 = new Audio(require("./success_scan.mp3"));
    this.audio2 = new Audio(require("./buzzer.wav"));
  },
  methods: {
    CheckStatus(Id) {
      var data = this.status.filter((x) => x.id == Id);
      return data[0].name;
    },
    async UpdateOutboundInfo() {
      this.btnLoadingMaster = true;
      try {
        let response = await axios.post(
          branchService_dotnet + "Branch/update-delivery-note-V2",
          {
            outboundID: this.OutboundInfo.id,
            phone: this.OutboundInfo.phone,
            driverName: this.OutboundInfo.driverName,
            carPlate: this.OutboundInfo.carPlate,
            branchID: this.OutboundInfo.branchID,
            remark: this.OutboundInfo.remark,
            createdBy: this.OutboundInfo.createdBy,
            isUpdateMaster: true,
          },
          { headers: header_token }
        );
        if (response.status == 200) {
          this.btnLoadingMaster = false;
          if (response.data.status == "success") {
            this.OutboundID = response.data.outboundInfoID;
            this.OutboundInfo.id = this.OutboundID;
            this.SetTitle();
            Swal.fire({
              toast: true,
              timer: 1500,
              position: "top",
              icon: "success",
              title: "บันทึกเรียบร้อย",
              showConfirmButton: false,
            });
          } else {
            Swal.fire({
              icon: "warning",
              title: response.data.message[this.language],
              showConfirmButton: true,
              confirmButtonText: "OK",
            });
          }

          // if (this.createOutbound){
          //   this.CreateOutboundItem()
          // }
        } else {
          this.btnLoadingMaster = false;
          Swal.fire({
            icon: "error",
            title: "ไม่สามารถทำรายการได้ กรุณาติดต่อผู้ดูแลระบบ",
            showConfirmButton: true,
            confirmButtonText: "OK",
          });
        }
      } catch (error) {
        this.btnLoadingMaster = false;
        Swal.fire({
          icon: "error",
          title: "ไม่สามารถทำรายการได้ กรุณาติดต่อผู้ดูแลระบบ",
          showConfirmButton: true,
          confirmButtonText: "OK",
        });
      }
    },
    SetTitle() {
      this.Ie = this.GenDN(this.OutboundID);
      this.ExcelFileName = "ใบส่งสินค้า : " + this.Ie;
      this.title = (this.OutboundID == 0 ? "เพิ่มรายการ" : "แก้ไขรายการ") + " " + this.Ie;
      document.title = "ใบส่งสินค้า " + this.Ie;
    },
    async ExportExcel(excelname) {
      if (this.ExcelData == null) {
        await this.LoadData();
      }
      document.getElementById(excelname).click();
    },
    async AddTrackNoList() {
      var tempTracking = this.trackingNo;

      if (tempTracking != "" && tempTracking != null) {
        tempTracking = tempTracking.trim().toUpperCase();
        var data = {
          tempRowID: this.tempRowID,
          id: 0,
          trackingNo: this.trackingNo,
          created: this.DateNowT(),
          createdBy: parseInt(localStorage.getItem("Branch_StaffID")),
          orderMasterId: null,
          referenceNumber: null,
          remark: null,
          status: 0,
        };
        this.tempRowID++;
        if (tempTracking.substring(0, 2) == "PA") {
          (data.trackingNo = null), (data.orderMasterId = tempTracking);
        }
        this.outboundItem.splice(0, 0, data);
        this.audio1.play();
        this.total = this.outboundItem.length;
        this.trackingNo = null;
        if (this.createOutbound) this.CreateOutboundItem();
      }
    },
    async CreateOutboundItem() {
      var IsStop = false;
      this.createOutbound = false;
      while (!IsStop) {
        this.total = this.outboundItem.length;

        var CopyData = this.outboundItem.filter(
          (item) => item.status == 0 || item.status == 1
        );

        var TotalCount = CopyData.length;

        for (var i = TotalCount - 1; i >= 0; i--) {
          if (CopyData[i].status == 0) {
            CopyData[i].status = 1;
          }
          try {
            let response = await axios.post(
              branchService_dotnet + "Branch/update-delivery-note-V2",
              {
                outboundID: this.OutboundInfo.id,
                phone: this.OutboundInfo.phone,
                driverName: this.OutboundInfo.driverName,
                carPlate: this.OutboundInfo.carPlate,
                branchID: this.OutboundInfo.branchID,
                remark: this.OutboundInfo.remark,
                createdBy: parseInt(localStorage.getItem("Branch_StaffID")),
                trackingNo: CopyData[i].trackingNo,
                orderMasterId: CopyData[i].orderMasterId,
                isUpdateMaster: false,
              },
              { headers: header_token }
            );
            this.trackingNo = null;
            if (response.status == 200 && response.data.status == "success") {
              if (this.OutboundID == 0 && response.data.outboundInfoID != 0) {
                this.OutboundID = response.data.outboundInfoID;
                this.OutboundInfo.id = this.OutboundID;
                this.SetTitle();
              }

              this.ExcelData = null;

              CopyData[i].id = response.data.outboundItemID;
              CopyData[i].trackingNo = response.data.trackingNo;
              CopyData[i].orderMasterId = response.data.orderMasterId;
              CopyData[i].status = response.data.code;
              if (response.data.code == 3 || response.data.code == 4) {
                CopyData[i].remark = response.data.message[this.language];
                this.audio2.play();
              } else {
                CopyData[i].remark = null;
              }
              this.save = this.outboundItem.filter((item) => item.status == 2).length;
              this.notSave = this.outboundItem.filter((item) => item.status == 3).length;
              this.duplicate = this.outboundItem.filter(
                (item) => item.status == 4
              ).length;
            } else {
              CopyData[i].status = 0;
            }
          } catch (err) {
            CopyData.status = 0;
          }
        }
        if (this.outboundItem.filter((item) => item.status == 0).length == 0) {
          IsStop = true;
        }
      }

      this.createOutbound = true;
    },

    async Delete(item) {
      Swal.fire({
        icon: "question",
        title: "แน่ใจหรือเปล่าว่าต้องการลบ?",
        text: "TrackingNo : " + item.trackingNo,
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.value) {
          if (item.id != 0) {
            let response = await axios.get(
              branchService_dotnet + "Branch/delete-delivery-note-detail?id=" + item.id,
              { headers: header_token }
            );
            if (response.status == 200 && response.data.status == "success") {
              this.outboundItem.splice(this.outboundItem.indexOf(item), 1);
            } else {
              Swal.fire({
                icon: "warning",
                title: response.data.message[this.languange],
                showConfirmButton: true,
                confirmButtonText: "OK",
              });
            }
          } else {
            this.outboundItem.splice(this.outboundItem.indexOf(item), 1);
          }
          this.total = this.outboundItem.length;
          this.save = this.outboundItem.filter((item) => item.status == 2).length;
          this.notSave = this.outboundItem.filter((item) => item.status == 3).length;
          this.duplicate = this.outboundItem.filter((item) => item.status == 4).length;
        }
      });
    },
    DeleteCount(item) {
      this.outboundItem.splice(this.outboundItem.indexOf(item), 1);

      if (item.status == 2)
        this.save = this.outboundItem.filter((x) => x.status == item.status).length;
      else if (item.status == 3)
        this.notSave = this.outboundItem.filter((x) => x.status == item.status).length;
      else if (item.status == 4)
        this.duplicate = this.outboundItem.filter((x) => x.status == item.status).length;
    },
    async LoadData() {
      let response = await axios.post(
        branchService_dotnet + "Branch/get-delivery-note-detail",
        {
          masterId: this.OutboundID,
        },
        { headers: header_token }
      );
      if (response.status == 200) {
        this.OutboundInfo = response.data.outboundInfo;
        this.OutboundInfo.id = response.data.outboundInfo.id;
        this.OutboundInfo.branchID = response.data.outboundInfo.branchId;
        this.OutboundInfo.createdBy = response.data.outboundInfo.createdBy;
        this.OutboundInfo.driverName = response.data.outboundInfo.driverName;
        this.OutboundInfo.phone = response.data.outboundInfo.phone;
        this.OutboundInfo.carPlate = response.data.outboundInfo.carPlate;
        this.OutboundInfo.remark = response.data.outboundInfo.remark;

        this.outboundItem = response.data.outboundItem;
        this.ExcelData = [];
        let counter = 0;
        for (var i in this.outboundItem) {
          this.outboundItem[i].createdBy = parseInt(
            localStorage.getItem("Branch_StaffID")
          );
          this.outboundItem[i].status = 2;
          this.outboundItem[i].createdExcel = formatDatetime1(
            this.outboundItem[i].created
          );
          this.outboundItem[i].no = this.outboundItem.length - counter;
          this.outboundItem[i].statusExcel = this.CheckStatus(
            this.outboundItem[i].status
          );
          this.ExcelData.unshift(this.outboundItem[i]);
          counter++;
        }
        this.total = this.outboundItem.length;
        this.save = this.outboundItem.filter((x) => x.status == 2).length;
        this.notSave = this.outboundItem.filter((x) => x.status == 3).length;
        this.duplicate = this.outboundItem.filter((x) => x.status == 4).length;
      } else {
        this.AlertError();
      }
    },
    toBack(path) {
      this.$router.push("/" + path);
    },
    GenPA,
    formatDatetime,
    GenDN,
    ChangeIE,
    Trim_value,
    formatMoney,
    AlertSuccess,
    AlertError,
    isNumberWNoDot,
    DateNowT,
    GenIdKey,
    formatDatetime1,
  },
};
</script>
